import React, { useEffect, useMemo, useState } from "react"
import * as auctionBackend from '../build/index.main.mjs'
import * as reverseBackend from '../build/index.main.mjs'
import {
  useHistory,
} from "react-router-dom";
import {
  getAccountTransaction,
  getAccInfo,
  fetchEscrow,
  getExplorer,
  formatCompactAddress,
  accountHasAsset,
} from '../functions'
import { Badge, Col, Image, Row, Spinner, Table } from "react-bootstrap";
import Button from '@mui/material/Button';
import CardLoader from "../loaders/CardLoader";
import BrowseCard from '../components/BrowseCard'
import PlaceholderCard from '../components/PlaceholderCard'
import RefreshIcon from '@mui/icons-material/Refresh';
import BasicModal from "../components/BasicModal";
import moment from "moment";
import Icon from "react-crypto-icons";
import md5 from "blueimp-md5";
import ContractLoader from "../loaders/ContractLoader";
import AuctionLoader from "../loaders/AuctionLoader";
import auctionService from "../service/auctionService";
import offerService from "../service/offerService";
import reverseService from "../service/reverseService";
import GavelIcon from '@mui/icons-material/Gavel';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import JoinButton from '../components/buttons/JoinButton';
import BuyButton from "../components/buttons/BuyButton";
const auctionStyle =
{
  //"margin": "auto",
  "paddingBottom": "100px"
}
const headingStyle =
{
  "height": "38px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "900",
  "fontSize": "32px",
  "lineHeight": "38px",
  "textAlign": "center",
  "letterSpacing": "0.1em",
  "textTransform": "uppercase",
  "color": "#2A3035",
  "opacity": "0.8",
}
const auctionTitleStyle =
{
  "height": "38px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "900",
  "fontSize": "32px",
  "lineHeight": "38px",
  "textAlign": "center",
  "letterSpacing": "0.1em",
  "textTransform": "uppercase",
  "color": "#2A3035",
  "opacity": "0.8",
}
const Browse = (props) => {
  var lz = require('lz-string');
  const history = useHistory()
  const WAValidator = require('@swyftx/api-crypto-address-validator')
  document.title = "Auction - NFT Jam"
  const {
    stdlib,
    acc,
    approvalProgram,
    firstBlock,
    ADDR_PLATFORM,
    ADDR_DISCOVERY,
    FIRSTBLOCK,
  } = props
  const [app, setApp] = useState(null)
  const [apps, setApps] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [next, setNext] = useState(null)
  const [state, setState] = useState({
    filter: (el) => true
  })
  const [showModal, setShowModal] = useState(false)
  const explorer = getExplorer()
  const handleBuy = async (acc, backend, appId, assetId, reservePrice, onSuccess) => {
    if (!acc) return
    try {
      let hasAsset = await accountHasAsset(acc.address, assetId)
      let ctc = await acc.contract(backend, appId)
      let a = ctc.apis.Bid
      if (!hasAsset) {
        await acc.tokenAccept(assetId)
      }
      await a.touch(null)
      await a.getPurchase(stdlib.parseCurrency(reservePrice))
    } catch (e) {
      console.log(e)
    }
  }
  const filterForSale = ({ status, app }) => (now => status === "live" && now > moment.unix(parseInt(app?.endSecs)).unix())(moment().unix())
  const getApps =
    //useMemo(() => async (addr, block) => {
    async (addr, block) => {
      let nextToken
      let currentRound
      let auctions = []
      do {
        let params = {
          //"min-round": firstBlock,
          //"min-round": FIRSTBLOCK,
          "min-round": block,
          "tx-type": "pay",
          "limit": 20,
          "next": nextToken
        }
        let res = await getAccountTransaction(addr, params)
        nextToken = (res?.data ?? {})['next-token']
        currentRound = (res?.data ?? {})['current-round']
        let transactions = Array(...(res?.data?.transactions ?? []))
        let senders = transactions.map(el => el?.sender)
        let accounts = (await Promise.all(senders.map(el => getAccInfo(el)))).map(el => el?.data)
        //console.log({res, transactions, senders, accounts})
        // TODO: save min conirmed round as last block 
        let live = await Promise.all(accounts.map(el => fetchEscrow(stdlib, acc, el.address)))
        console.log({ live, nextToken })
        auctions.push(live)
        //} while (nextToken !== undefined)
      } while (0)
      // TODO: add seek end later
      let all = auctions.flatMap(el => el)
      localStorage.setItem('firstblock', currentRound)
      return all
      //}, [ADDR_DISCOVERY, firstBlock])
    }
  useEffect(() => {
    if (false && apps) {
      let firstblock = localStorage.getItem('firstblock')
      console.log({ firstblock })
      console.log("Fetch next")
      fetchNext(firstblock || FIRSTBLOCK)
        .then(next => {
          console.log({ next })
          if (next.length === 0) {
            console.log("Do nothing")
            /*
            console.log("do nothing")
            console.log("fetch live auction info ")
            let addresses = apps.filter(el => el.status === 'live');
            Promise.all(addresses.map(el => fetchEscrow(el.addr)))
              .then(live => live.filter(el => true))
              .then(live => {
                let storedLiveIntegrity = localStorage.getItem('live-integrity')
                let liveIntegrity = md5(JSON.stringify(live))
                console.log({live, storedLiveIntegrity, liveIntegrity})
                if(storedLiveIntegrity !== liveIntegrity) {
                  console.log("Update auctions")
                }
              })
              */
          } else {
            // get apps
            console.log("Do something")
            console.log("New transactions")
            console.log({ firstBlock, next: next.filter(el => el.confirmedRound > firstBlock) })
            // add new auctions to live or closed
            let live = JSON.parse(localStorage.getItem('live'))
            let closed = JSON.parse(localStorage.getItem('closed'))
            next.forEach(el => {
              if (el.status === 'live') {
                live = [el, ...live]
              } else { // closed
                closed = [el, ...closed]
              }
            })
            localStorage.setItem('live-integrity', md5(live))
            localStorage.setItem('live', JSON.stringify(live))
            localStorage.setItem('closed-integrity', md5(closed))
            localStorage.setItem('closed', JSON.stringify(closed))
            setApps([...live, ...closed])
            /*
            let newApps = [...next, ...apps];
            (apps => {
              localStorage.setItem('auctions-integrity', md5(apps))
              localStorage.setItem('auctions', lz.compress(apps))
            })(JSON.stringify(newApps))
            (apps => {
              localStorage.setItem('live-integrity', md5(apps))
              localStorage.setItem('live', lz.compress(apps))
            })(JSON.stringify(newApps.filter(el => el.status === "live")))
            console.log("Updating apps")
            setApps(newApps)
            */
          }
        })
    }
  }, [apps])
  useEffect(() => {
    if (!apps) {

      const storedLiveAuctions = localStorage.getItem('live')
      const storedClosedAuctions = localStorage.getItem('closed')
      //const storedLiveAuctions = localStorage.getItem('live')
      //const storedClosedAuctions = localStorage.getItem('closed')
      // use stored auctions
      if (storedLiveAuctions && storedClosedAuctions) {
        console.log("Loading stored auctions")
        let live = JSON.parse(storedLiveAuctions)
        let closed = JSON.parse(storedClosedAuctions)
        console.log({ live, closed })
        //let live = JSON.parse(lz.decompressFromUTF16(storedLiveAuctions))
        //let closed = JSON.parse(lz.decompressFromUTF16(storedClosedAuctions))
        /*
        let firstblock = localStorage.getItem('firstblock')
        console.log("Fetch next")
        fetchNext(firstBlock || FIRSTBLOCK)
          .then(next => {
            console.log({ next })
          })
          */
        //setApps(auctions)
        setApps([...live, ...closed])
      } else {
        if (true) {
          // fetch all auctions using remote
          console.log("Fetching all using remote")
          Promise.all([
            //offerService.get(),
            reverseService.get({status: 'live'}),
            auctionService.get({status: 'live'}),
          ])
            .then(([...all]) => [].concat(all).flatMap(el => el)) // combine service responses
            .then(apps => apps.sort((a, b) => b.appId - a.appId)) // sort apps by id
            .then(apps => setTimeout(() => {
              (lst => lst.forEach(status => {
                (list => {
                  localStorage.setItem(`${status}-count`, list.length);
                  (apps => {
                    localStorage.setItem(`${status}-integrity`, md5(apps))
                    localStorage.setItem(`${status}`, apps)
                  })(JSON.stringify(list))
                })(apps?.filter(el => el.status === status))
              }))([
                'live',
                'closed',
              ])
              /*
              localStorage.setItem('firstblock',
                apps[0].transactions[0]['confirmed-round'] + 1)
              */
              setApps(apps)
            }, 1000))
        } else {
          console.log("Fetching all using api")
          getAccountTransaction(ADDR_DISCOVERY)
            .then(data => console.log({ data }))
          return
          getApps(ADDR_DISCOVERY, FIRSTBLOCK)
            .then((apps) => setTimeout(() => {
              (lst => lst.forEach(status => {
                (list => {
                  localStorage.setItem(`${status}-count`, list.length);
                  (apps => {
                    localStorage.setItem(`${status}-integrity`, md5(apps))
                    localStorage.setItem(`${status}`, apps)
                  })(JSON.stringify(list))
                })(apps?.filter(el => el.status === status))
              }))([
                'live',
                'closed',
              ])
              localStorage.setItem(`auction-integrity`, md5(apps))
              localStorage.setItem(`auction`, JSON.stringify(apps))
              setApps(apps)
            }, 1000))
        }
      }
    }
  }, [apps])

  const fetchNext = async (firstBlock) => {
    //const firstBlock = apps.filter(({ status }) => status === "live")[0].transactions[0]['confirmed-round']
    const storedFirstblock = localStorage.getItem('firstBlock') || FIRSTBLOCK
    //const firstBlock = apps[0].transactions[0]['confirmed-round']
    //const firstBlock = storedFirstblock
    let res = await getApps(ADDR_DISCOVERY, firstBlock, approvalProgram)
    return res
  }

  const handleGalleryClick = (id) => {
    history.push(`/auction/${id}`)
  }

  const handleMore = async () => {
    localStorage.setItem('firstblock', FIRSTBLOCK)
    localStorage.removeItem('firstblock')
    localStorage.removeItem('auction')
    localStorage.removeItem('auction-integrity')
    localStorage.removeItem('live')
    localStorage.removeItem('live-integrity')
    localStorage.removeItem('closed')
    localStorage.removeItem('closed-integrity')
    setState({ ...state, filter: () => true })
    setApps(null)
  }

  const handleCardClick = (addr) => {
    console.log("CARD CLICK")
    console.log({ addr })
    setApp(apps.filter(el => el.addr === addr)[0])
    setShowModal(true)
  }

  const isOver = (app) => (now =>
    now > moment.unix(parseInt(app?.endSecs)).unix())(moment().unix())

  const buttons = <>
    <Button onClick={() => setState({ ...state, label: "ALL", filter: (_) => true })}>ALL</Button>
    <Button onClick={() => setState({
      ...state,
      label: "LIVE",
      filter: (now => ({ status, app }) => true
        && status === "live"
        && now <= moment.unix(parseInt(app?.endSecs)).unix())(moment().unix())
    })}>LIVE</Button>
    <Button onClick={() => setState({
      ...state,
      label: "FOR SALE",
      filter: filterForSale
    })
    }>FOR SALE</Button>
    {false && <Button onClick={() => setState({
      ...state,
      label: "CLOSED",
      filter: ({ status }) => status === "closed"
    })}>CLOSED</Button>}
    {false && <Button onClick={() => setState({
      ...state,
      label: "SOLD",
      filter: ({ sold }) => sold
    })}>SOLD</Button>}
    <Button onClick={() => setState({
      ...state,
      label: "MY AUCTIONS",
      filter: ({ auctioneer }) => auctioneer === acc.address
    })}>MY AUCTIONS</Button>
    {false && <Button onClick={() => setState({ ...state, filter: ({ auctioneer, status }) => status === "live" && auctioneer[0] === acc.address })}>LIVE</Button>}
    {false && <Button onClick={() => setState({ ...state, filter: ({ auctioneer, status }) => status === "closed" && auctioneer[0] === acc.address })}>CLOSED</Button>}
    <Button onClick={() => setState({ ...state, label: "BIDDING", filter: ({ auctioneer, status, history }) => status === "live" && history.slice(0, -3).map(({ sender }) => sender).includes(acc.address) })}>BIDDING</Button>
    <Button onClick={handleMore}><RefreshIcon /></Button>
  </>
  const colProps = {
    xs: 6
  }
  const filteredApps = apps ? apps?.filter(state.filter) : []
  /*
  if (filteredApps && filteredApps.length > 1) {
    colProps.sm = apps.length > 1 ? 6 : 12
  }
  */
  if (filteredApps && filteredApps.length > 3) {
    colProps.md = apps.length > 3 ? 4 : 6
  }
  /*
  if (filteredApps && filteredApps.length > 4) {
    colProps.lg = apps.length > 4 ? 3 : 4
  }
  */
  /*
  if (filteredApps && filteredApps.length > 5) {
    colProps.xl = apps.length > 5 ? 3 : 2
  }
  */
  return <div id="browse" style={auctionStyle}>
    <BasicModal open={showModal} handleOpen={() => setShowModal(true)} handleClose={() => setShowModal(false)}>
      <Row>
        <Col xs={12}>
          <Image src={app?.image} fluid />
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={12} sm={6}>
              <span>APP ID:</span>
              <a style={{ float: 'right' }} href={`${explorer}/application/${app?.appId}`} target="_blank" rel="noopener noreferrer">{app?.appId}</a>
            </Col>
            <Col xs={12} sm={6}>
              <span>ASSET ID:</span>
              <a style={{ float: 'right' }} href={`${explorer}/asset/${app?.assetId}`} target="_blank" rel="noopener noreferrer">{app?.assetId}</a>
            </Col>
            <Col xs={12} sm={6}>
              <span>ESCROW:</span>
              <a style={{ float: 'right' }} href={`${explorer}/address/${app?.addr}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(app?.addr)}</a>
            </Col>
            <Col xs={12} sm={6}>
              <span>CREATOR:</span>
              <a style={{ float: 'right' }} href={`${explorer}/address/${app?.asset?.creator}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(app?.asset?.creator)}</a>
            </Col>
            {false && <Col xs={6}>
              <span>OWNER:</span><a style={{ float: "right" }} href={`${explorer}/address/${app?.status === "live" ? app?.auctioneer : app?.receiver}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(app?.status === "live" ? app?.auctioneer : app?.receiver)}</a>
            </Col>}
            {app?.endSecs && <Col xs={12}>
              <span>END:</span>
              <span style={{ float: "right" }}>{moment.unix(app?.endSecs).calendar()}</span>
            </Col>}
          </Row>
        </Col>
        {(history => <>
          <Col xs={12}>
            {history?.length > 0 && <span>BID HISTORY:</span>}
          </Col>
          <Col className="browse-bid-history" xs={12} style={{ height: "150px", overflow: "scroll" }}>
            <Table className="w-100" borderless striped>
              <tbody>
                {history.map(({ amount, sender, roundTime }, i) =>
                  <tr>
                    <td>#{history?.length - i}</td>
                    <td><a href={`${explorer}/address/${sender}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(sender)}</a></td>
                    <td style={{ textAlign: "right" }}><Icon size={12} name="algo" />&nbsp;{(amount / 1000000).toFixed(2)}</td>
                    <td style={{ textAlign: "right" }}>{moment.unix(roundTime).format("LTS")}</td>
                  </tr>)}
              </tbody>
            </Table>
          </Col>
        </>)((app?.history ?? []).slice(0, -3))}
        {app?.status === 'live' &&
          <Col>
            {app?.assetId === 333321830 // GoGoats Nature #16
              ? <>
                <a href={`https://app2.nftjam.net/auction/427207800`} target="_blank">JOIN AUCTION</a><br />
                <span class="text-danger">window opens to older version of app</span>
              </>
              : moment().unix() < moment.unix(app?.app?.endSecs).unix() && <a style={{ float: "right" }} href={`/auction/${app?.addr}`} target="_blank">JOIN AUCTION</a>}
          </Col>}
      </Row>
    </BasicModal >
    {buttons}
    {
      apps ? <>
        <div style={{
          ...auctionTitleStyle,
          "fontWeight": "100",
          "color": "white",
          "marginTop": "100px",
        }}>
          {state.label || "ALL"} {apps.filter(state.filter)
            .filter(el => el?.asset?.name !== "Yieldly") // TODO: maybe move this somewhere else
            .length}
        </div>
        <Row>
          {apps
            .filter(state.filter)
            .filter(el => el?.asset?.name !== "Yieldly") // TODO: maybe move this somewhere else
            .map((el, i) =>
              <Col className="mt-4"
                key={el.appId}
                {...colProps}
                style={{
                }}>
                <div className="mb-4">
                  <Badge bg="secondary" onClick={() => setState({ ...state, label: "ALL", filter: el => el })}>all</Badge>{' '}
                  {el.type === "auction"
                    ? <Badge bg="primary" onClick={() => setState({ ...state, label: "AUCTION", filter: el => el['type'] === 'auction' })}>{el.type}</Badge>
                    : <Badge bg="warning" onClick={() => setState({ ...state, label: "REVERSE", filter: el => el['type'] === 'reverse' })}>{el.type}</Badge>}{' '}
                  {el.status === 'live'
                    ? (isOver(el.app)
                      ? <Badge bg="info" onClick={() => setState({ ...state, label: "FOR SALE", filter: filterForSale })}>sale</Badge> 
                      : <Badge bg="success">{el.status}</Badge>)
                    : <Badge bg="danger">{el.status}</Badge>}
                </div>
                {el.type === 'auction'
                  ? <BrowseCard acc={acc} stdlib={stdlib} {...el} {...el.app} index={i} onClick={handleCardClick} onUpdate={handleMore}>
                   {moment().unix() > moment.unix(parseInt(el.app.endSecs)).unix() 
                    ? <JoinButton label="Buy NFT" onClick={() => history.push(`/${el.type}/${el.appId}`)} />
                    : <JoinButton onClick={() => history.push(`/${el.type}/${el.appId}`)} />}
                  {/*moment().unix() > moment.unix(parseInt(el.app.endSecs)).unix()
                    ? <BuyButton onClick={() => handleBuy(acc, auctionBackend, el.appId, el.assetId, el.app.reservePrice)} {...el.app} />*/}
                </BrowseCard>
                  : <BrowseCard acc={acc} stdlib={stdlib} {...el} {...el.app} index={i} onClick={handleCardClick} onUpdate={handleMore}>
                    {el.type !== 'closed' && <JoinButton onClick={() => history.push(`/${el.type}/${el.appId}`)} />}
                </BrowseCard>}
              </Col>
            )}
        </Row>
      </> :
        <Row>
          {[...Array(20)].map((el, i) =>
            <Col key={i} className="mt-4" xs={6} md={4} >
              <PlaceholderCard />
            </Col>)}
        </Row>
    }
  </div >
}

export default Browse